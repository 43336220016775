import React from "react";

const style = {
  marginTop: "20vh",
  bottom: "2",
};
function About() {
  return (
    <div id="about" style={style}>
      <div className="container">
        <div className="section-header">
          <h3>About Us</h3>
          <p>
            Two decades of IT, Cloud experience team formed a group and began
            the "Clouder.ai" journey.{" "}
          </p>
        </div>

        <div className="row about-cols">
          <div className="col-md-4 wow fadeInUp">
            <div className="about-col">
              <div className="img">
                <img
                  src="assets/img/about-mission.jpg"
                  alt=""
                  className="img-fluid"
                />
                <div className="icon">
                  <i className="ion-ios-speedometer-outline"></i>
                </div>
              </div>
              <h2 className="title">
                <a href="#">Our Mission</a>
              </h2>
              <p>
                Empower digital experience with the lowest cost for the
                customers.
              </p>
            </div>
          </div>

          <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className="about-col">
              <div className="img">
                <img
                  src="assets/img/about-plan.jpg"
                  alt=""
                  className="img-fluid"
                />
                <div className="icon">
                  <i className="ion-ios-list-outline"></i>
                </div>
              </div>
              <h2 className="title">
                <a href="#">Our Plan</a>
              </h2>
              <p>
                Let us take the opportunity to dig your quandaries with Free
                Assessment and Free Consulting. We will introduce new
                possibilities with Clouder.ai solutions.
              </p>
            </div>
          </div>

          <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
            <div className="about-col">
              <div className="img">
                <img
                  src="assets/img/about-vision.jpg"
                  alt=""
                  className="img-fluid"
                />
                <div className="icon">
                  <i className="ion-ios-eye-outline"></i>
                </div>
              </div>
              <h2 className="title">
                <a href="#">Our Vision</a>
              </h2>
              <p>
                Let's make it simple - Simplify the solution with simplified
                cost.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
