import React from "react";

const style = {
  marginTop: "20vh",
  bottom: "2",
};

function Services() {
  return (
    <div id="services" style={style}>
      <div className="container">
        <header className="section-header wow fadeInUp">
          <h3>Services</h3>
          <p>
            IT Consulting and Training | Cloud Development | Cloud Migration |
            Data and AI Solutions
          </p>
        </header>

        <div className="row">
          <div
            className="col-lg-4 col-md-6 box wow bounceInUp"
            data-wow-duration="1.4s"
          >
            <div className="icon">
              <i className="ion-ios-analytics-outline"></i>
            </div>
            <h4 className="title">
              <a href="#">IT Consulting and Training</a>
            </h4>
            <p className="description">
              We enlighten on niche skills and technologies to our stakeholders,
              which includes training in Azure IaaS, PaaS, DBaaS, Power BI and
              modern AI tech stack.
            </p>
          </div>
          <div
            className="col-lg-4 col-md-6 box wow bounceInUp"
            data-wow-duration="1.4s"
          >
            <div className="icon">
              <i className="ion-ios-bookmarks-outline"></i>
            </div>
            <h4 className="title">
              <a href="#">Cloud Development and Migration</a>
            </h4>
            <p className="description">
              Our Clouder consultants have tremendous experience in Azure Cloud
              Computing who will analyze your existing, upcoming requirements to
              determine which cloud solutions would best meet the client's needs
              and they make recommendations based on their analysis.
            </p>
          </div>
          <div
            className="col-lg-4 col-md-6 box wow bounceInUp"
            data-wow-duration="1.4s"
          >
            <div className="icon">
              <i className="ion-ios-paper-outline"></i>
            </div>
            <h4 className="title">
              <a href="#">Data and AI Solutions</a>
            </h4>
            <p className="description">
              A decade of experience in Dataware house traditional and modern
              solutions. We provide sophisticated data solutions using Azure
              Databricks, Data lake, Synapse Analytics (aka - SQL DW),
              orchestrate data using Azure Data Factory, create a reporting
              layer with Power BI.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
