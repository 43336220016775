import React from "react";
import { NavLink } from "react-router-dom";

const isActive = {
  color: "#18d26e",
};
function header() {
  return (
    <div id="header">
      <div id="logo" className="pull-left">
        <h1>
          <NavLink to="/" className="scrollto">
            Clouder.ai
          </NavLink>
        </h1>
      </div>
      <nav id="nav-menu-container">
        <ul className="nav-menu">
          <li>
            <NavLink exact to="/" activeStyle={isActive}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" activeStyle={isActive}>
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/services" activeStyle={isActive}>
              Our Services
            </NavLink>
          </li>
          <li>
            <NavLink to="/skills" activeStyle={isActive}>
              Our Skills
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" activeStyle={isActive}>
              Say Hello
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default header;
