import React from "react";

const style = {
  marginTop: "20vh",
  bottom: "2",
};

function Contact() {
  return (
    <section id="contact" className="section-bg wow fadeInUp" style={style}>
      <div className="container">
        <div className="section-header">
          <h3>Hello !!</h3>
          <p>Please allow us to serve you better, contact -</p>
        </div>

        <div className="row contact-info">
          <div className="col-md-4">
            <div className="contact-address">
              <i className="ion-ios-location-outline"></i>
              <h3>Locations</h3>
              <address>USA | India</address>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-phone">
              <i className="ion-ios-telephone-outline"></i>
              <h3>Phone Number</h3>
              <p>
                <a href="tel:+16785419951">+1 678-541-9951</a>
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-email">
              <i className="ion-ios-email-outline"></i>
              <h3>Email</h3>
              <p>
                <a href="mailto:hello@clouder.ai">hello@clouder.ai</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
