import React from "react";

const style = {
  marginTop: "20vh",
  bottom: "2",
};

function Skills() {
  return (
    <div id="skills" style={style}>
      <div className="container">
        <header className="section-header">
          <h3>Our Skills</h3>
          <p>
            We are passionate about cutting edge technology, we continuously
            evolve the niche skills as industry demands.
          </p>
        </header>

        <div className="skills-content">
          <div className="progress">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span className="skill">
                Data and AI<i className="val">100%</i>
              </span>
            </div>
          </div>

          <div className="progress">
            <div
              className="progress-bar bg-info"
              role="progressbar"
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span className="skill">
                Azure Infrastructure (IaaS)<i className="val">90%</i>
              </span>
            </div>
          </div>

          <div className="progress">
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span className="skill">
                Azure PaaS Services<i className="val">85%</i>
              </span>
            </div>
          </div>

          <div className="progress">
            <div
              className="progress-bar bg-danger"
              role="progressbar"
              aria-valuenow="70"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span className="skill">
                Web|Mobile|DevOps<i className="val">80%</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
