import React from "react";

function SlideShow() {
  return (
    <div id="intro">
      <div className="intro-container">
        <div
          id="introCarousel"
          className="carousel  slide carousel-fade"
          data-ride="carousel"
        >
          <ol className="carousel-indicators"></ol>

          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
              <div className="carousel-background">
                <img src="assets/img/intro-carousel/1.jpg" alt="" />
              </div>
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2>Digital Transformations Consulting</h2>
                  <p>
                    We analyze your company's infrastructure and finding gaps in
                    your end to end services. We focus on not only increasing a
                    company's short-term performance but also meeting their
                    long-term strategic objectives.
                  </p>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="carousel-background">
                <img src="assets/img/intro-carousel/2.jpg" alt="" />
              </div>
              <div className="carousel-container">
                <div className="carousel-content"></div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="carousel-background">
                <img src="assets/img/intro-carousel/3.jpg" alt="" />
              </div>
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2>Data and Artificial Intelligence</h2>
                  <p>
                    We will analyze Gigabytes to Petabytes of data using
                    Artificial Intelligence tools and technologies.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="carousel-item">
              <div className="carousel-background">
                <img src="assets/img/intro-carousel/4.jpg" alt="" />
              </div>
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2>What | How</h2>
                  <p>
                    Let's explore together "your needs and opportunities",{" "}
                    <br />
                    leave it us "How to achieve".
                  </p>
                </div>
              </div>
            </div>
          </div>

          <a
            className="carousel-control-prev"
            href="#introCarousel"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon ion-chevron-left"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>

          <a
            className="carousel-control-next"
            href="#introCarousel"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon ion-chevron-right"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SlideShow;
