import "./App.css";
import About from "./Components/About";
import Header from "./Components/header";
import SlideShow from "./Components/slideshow";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Services from "./Components/Services";
import Skills from "./Components/Skills";
import Contact from "./Components/Contact us";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/" component={SlideShow} />
          <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route path="/skills" component={Skills} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
